import { createIcon } from '@chakra-ui/react'

export const Bottle = createIcon({
  displayName: 'Bottle',
  viewBox: '0 0 11 24',
  path: [
    <path
      key="path1"
      d="M5.49969 9.16532C7.65093 9.16532 9.6374 9.97508 10.9995 11.3602V10.4755C10.9995 8.89581 9.85659 7.49073 8.10925 6.86499V3.99976H2.89028V6.86499C2.10827 7.14507 1.42937 7.59039 0.91664 8.16459C0.317964 8.83958 0 9.63998 0 10.4743V11.3591C1.36924 9.97511 3.34845 9.16532 5.49969 9.16532Z"
      fill="currentColor"
    />,
    <path
      key="path2"
      d="M1.83328 23.9999H9.1664C10.1747 23.9999 10.9997 23.3249 10.9997 22.4999V19.9651C9.62472 21.3503 7.65108 22.1647 5.49984 22.1647C3.34288 22.1647 1.35626 21.3444 0 19.9546V22.4999C0 23.3249 0.824976 23.9999 1.83328 23.9999Z"
      fill="currentColor"
    />,
    <path
      key="path3"
      d="M5.5 21.1653C7.81022 21.1653 9.894 20.0157 10.9456 18.1605C10.9814 18.0949 11 18.0199 11 17.9495V13.3746C11 13.2996 10.9814 13.2292 10.9456 13.1648C9.90002 11.3144 7.81037 10.1648 5.5 10.1648C3.18963 10.1648 1.10013 11.3144 0.0544255 13.1648C0.018619 13.2304 0 13.2996 0 13.3746V17.9495C0 18.0199 0.0186182 18.0949 0.0544255 18.1593C1.1057 20.0144 3.18963 21.1653 5.5 21.1653ZM4.54039 13.5C4.99299 13.5 5.42696 13.6348 5.76209 13.8644C6.09866 13.6347 6.52546 13.5 6.98378 13.5C7.99209 13.5 8.81706 14.2055 8.81706 15.0644C8.81706 16.7695 6.15881 17.9496 6.04852 17.9941C5.95685 18.0339 5.85946 18.0539 5.76063 18.0539C5.66324 18.0539 5.56441 18.0339 5.47275 17.9941C5.35674 17.9496 2.7042 16.7695 2.7042 15.0644C2.70707 14.2054 3.53208 13.5 4.54039 13.5Z"
      fill="currentColor"
    />,
    <path
      key="path4"
      d="M2.67161 3.00002H8.33058C8.66715 3.00002 8.94216 2.77502 8.94216 2.49962L8.94072 0.500399C8.94072 0.225009 8.66573 0 8.32914 0H2.67018C2.3336 0 2.05859 0.225 2.05859 0.500399V2.50079C2.06003 2.77501 2.33502 3.00002 2.67161 3.00002Z"
      fill="currentColor"
    />,
  ],
})
