import { createIcon } from '@chakra-ui/react'

export const Facebook = createIcon({
  displayName: 'Facebook',
  viewBox: '0 0 32 32',
  path: [
    <circle key="path1" cx="16" cy="16" r="16" fill="currentFill" />,
    <path
      key="path2"
      d="M11 14.2222V17.7778H13.6667V24H17.2222V17.7778H19.8889L20.7778 14.2222H17.2222V12.4444C17.2222 12.2087 17.3159 11.9826 17.4826 11.8159C17.6493 11.6492 17.8754 11.5556 18.1111 11.5556H20.7778V8H18.1111C16.9324 8 15.8019 8.46825 14.9684 9.30175C14.1349 10.1352 13.6667 11.2657 13.6667 12.4444V14.2222H11Z"
      fill="currentColor"
    />,
  ],
})
