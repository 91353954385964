import { createIcon } from '@chakra-ui/react'

export const Cross = createIcon({
  displayName: 'Cross',
  viewBox: '0 0 18 18',
  path: (
    <path
      opacity="0.5"
      d="M0.292948 16.2904C-0.0976067 16.6809 -0.0976554 17.314 0.292839 17.7046C0.683333 18.0951 1.3165 18.0952 1.70705 17.7047L0.292948 16.2904ZM17.7071 1.70716C18.0976 1.31667 18.0977 0.683502 17.7072 0.292948C17.3167 -0.0976067 16.6835 -0.0976554 16.2929 0.292839L17.7071 1.70716ZM1.70705 0.292839C1.3165 -0.0976554 0.683333 -0.0976067 0.292839 0.292948C-0.0976554 0.683502 -0.0976067 1.31667 0.292948 1.70716L1.70705 0.292839ZM16.2929 17.7047C16.6835 18.0952 17.3167 18.0951 17.7072 17.7046C18.0977 17.314 18.0976 16.6809 17.7071 16.2904L16.2929 17.7047ZM1.70705 17.7047L17.7071 1.70716L16.2929 0.292839L0.292948 16.2904L1.70705 17.7047ZM0.292948 1.70716L16.2929 17.7047L17.7071 16.2904L1.70705 0.292839L0.292948 1.70716Z"
      fill="currentColor"
    />
  ),
})
