import { createIcon } from '@chakra-ui/react'

export const Filter = createIcon({
  displayName: 'Filter',
  viewBox: '0 0 10 6',
  path: (
    <path
      d="M3.5 4.875C3.5 4.77554 3.53951 4.68016 3.60984 4.60983C3.68016 4.53951 3.77554 4.5 3.875 4.5H6.125C6.22446 4.5 6.31984 4.53951 6.39017 4.60983C6.46049 4.68016 6.5 4.77554 6.5 4.875C6.5 4.97446 6.46049 5.06984 6.39017 5.14017C6.31984 5.21049 6.22446 5.25 6.125 5.25H3.875C3.77554 5.25 3.68016 5.21049 3.60984 5.14017C3.53951 5.06984 3.5 4.97446 3.5 4.875ZM2 2.625C2 2.52554 2.03951 2.43016 2.10984 2.35984C2.18016 2.28951 2.27554 2.25 2.375 2.25H7.625C7.72446 2.25 7.81984 2.28951 7.89017 2.35984C7.96049 2.43016 8 2.52554 8 2.625C8 2.72446 7.96049 2.81984 7.89017 2.89016C7.81984 2.96049 7.72446 3 7.625 3H2.375C2.27554 3 2.18016 2.96049 2.10984 2.89016C2.03951 2.81984 2 2.72446 2 2.625ZM0.5 0.375C0.5 0.275544 0.539509 0.180161 0.609835 0.109835C0.680161 0.0395089 0.775544 0 0.875 0H9.125C9.22446 0 9.31984 0.0395089 9.39017 0.109835C9.46049 0.180161 9.5 0.275544 9.5 0.375C9.5 0.474456 9.46049 0.569839 9.39017 0.640165C9.31984 0.710491 9.22446 0.75 9.125 0.75H0.875C0.775544 0.75 0.680161 0.710491 0.609835 0.640165C0.539509 0.569839 0.5 0.474456 0.5 0.375Z"
      fill="currentColor"
    />
  ),
})
