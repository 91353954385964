import { createIcon } from '@chakra-ui/react'

export const Logout = createIcon({
  displayName: 'Logout',
  viewBox: '0 0 14 16',
  path: (
    <path
      d="M7.95985 14.0001C9.57423 14.0048 11.1211 13.3532 12.246 12.1954L13.2754 13.2032C11.8898 14.6207 9.99411 15.426 8.01219 15.441C6.02938 15.456 4.12251 14.6788 2.71539 13.2819C1.3082 11.8851 0.516941 9.98386 0.516941 8.00098C0.516941 6.01817 1.3082 4.11778 2.71539 2.72098C4.12258 1.32411 6.02955 0.546915 8.01219 0.560979C9.99406 0.575979 11.8896 1.3813 13.2754 2.79879L12.246 3.80659C11.1866 2.72472 9.75697 2.08534 8.24377 2.01408C6.73157 1.9419 5.2476 2.44534 4.09056 3.42221C2.93369 4.39908 2.18837 5.77721 2.00556 7.27997H9.10237L6.94237 5.11997L7.95956 4.10278L11.3486 7.49086C11.6289 7.77212 11.6289 8.22773 11.3486 8.50899L7.95956 11.8971L6.94237 10.8799L9.10237 8.71988H2.00556C2.18088 10.1758 2.88401 11.5164 3.98089 12.4896C5.07776 13.4617 6.49369 13.9991 7.95985 14.0001Z"
      fill="currentColor"
    />
  ),
})
