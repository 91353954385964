import { createIcon } from '@chakra-ui/react'

export const Arrow = createIcon({
  displayName: 'Arrow',
  viewBox: '0 0 8 14',
  path: (
    <path
      d="M1 1L7 7L1 13"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
})
