import { createIcon } from '@chakra-ui/react'

export const Copyright = createIcon({
  displayName: 'Copyright',
  viewBox: '0 0 27 24',
  path: [
    <path
      key="path1"
      d="M13.6929 20C18.4036 20 22.2225 16.4183 22.2225 12C22.2225 7.58172 18.4036 4 13.6929 4C8.98214 4 5.16333 7.58172 5.16333 12C5.16333 16.4183 8.98214 20 13.6929 20Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      key="path2"
      d="M15.8249 9.75C15.3617 9.36813 14.7906 9.11931 14.18 9.0334C13.5694 8.94749 12.9452 9.02815 12.3824 9.26569C11.8196 9.50323 11.342 9.88756 11.0071 10.3726C10.6721 10.8576 10.4939 11.4227 10.4939 12C10.4939 12.5773 10.6721 13.1424 11.0071 13.6274C11.342 14.1124 11.8196 14.4968 12.3824 14.7343C12.9452 14.9719 13.5694 15.0525 14.18 14.9666C14.7906 14.8807 15.3617 14.6319 15.8249 14.25"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
})
