import { createIcon } from '@chakra-ui/react'

export const Profile = createIcon({
  displayName: 'Profile',
  viewBox: '0 0 12 12',
  path: (
    <path
      d="M8.16417 6.41416C8.73606 5.96421 9.15349 5.3472 9.35839 4.64896C9.56328 3.95072 9.54545 3.20598 9.30738 2.51835C9.0693 1.83072 8.62281 1.23439 8.03004 0.812324C7.43727 0.390261 6.72768 0.163452 6 0.163452C5.27232 0.163452 4.56273 0.390261 3.96996 0.812324C3.37718 1.23439 2.9307 1.83072 2.69262 2.51835C2.45455 3.20598 2.43671 3.95072 2.64161 4.64896C2.8465 5.3472 3.26394 5.96421 3.83583 6.41416C2.85588 6.80677 2.00083 7.45795 1.36185 8.29828C0.722872 9.13861 0.32391 10.1366 0.207498 11.1858C0.199072 11.2624 0.205816 11.3399 0.227346 11.4139C0.248876 11.4879 0.284771 11.557 0.33298 11.6171C0.430342 11.7385 0.571955 11.8163 0.726665 11.8333C0.881375 11.8503 1.03651 11.8052 1.15794 11.7078C1.27937 11.6105 1.35715 11.4689 1.37417 11.3142C1.50226 10.1739 2.04598 9.12072 2.90146 8.35595C3.75694 7.59119 4.86419 7.16843 6.01167 7.16843C7.15914 7.16843 8.26639 7.59119 9.12187 8.35595C9.97735 9.12072 10.5211 10.1739 10.6492 11.3142C10.665 11.4575 10.7334 11.5899 10.8411 11.6858C10.9489 11.7816 11.0883 11.8342 11.2325 11.8333H11.2967C11.4496 11.8157 11.5893 11.7384 11.6855 11.6182C11.7816 11.498 11.8264 11.3447 11.81 11.1917C11.693 10.1394 11.2919 9.1389 10.6497 8.29728C10.0074 7.45565 9.1482 6.80469 8.16417 6.41416V6.41416ZM6 5.99999C5.53851 5.99999 5.08738 5.86314 4.70367 5.60675C4.31995 5.35036 4.02088 4.98595 3.84428 4.55959C3.66768 4.13323 3.62147 3.66407 3.7115 3.21145C3.80153 2.75883 4.02376 2.34306 4.35008 2.01674C4.67641 1.69042 5.09217 1.46819 5.54479 1.37816C5.99741 1.28813 6.46657 1.33433 6.89293 1.51094C7.31929 1.68754 7.68371 1.98661 7.9401 2.37033C8.19649 2.75404 8.33333 3.20517 8.33333 3.66666C8.33333 4.2855 8.0875 4.87899 7.64992 5.31657C7.21233 5.75416 6.61884 5.99999 6 5.99999Z"
      fill="currentColor"
    />
  ),
})
