import { createIcon } from '@chakra-ui/react'

export const Portfolio = createIcon({
  displayName: 'Portfolio',
  viewBox: '0 0 14 14',
  path: (
    <path
      d="M6.99994 7.00001L1.34994 1.35001C1.28124 1.28028 1.235 1.19158 1.21718 1.09533C1.19935 0.999086 1.21076 0.899707 1.24994 0.810006C1.2851 0.71708 1.34823 0.637355 1.43062 0.58183C1.51302 0.526305 1.61061 0.497715 1.70994 0.500006H12.2899C12.3893 0.497715 12.4869 0.526305 12.5693 0.58183C12.6516 0.637355 12.7148 0.71708 12.7499 0.810006C12.7891 0.899707 12.8005 0.999086 12.7827 1.09533C12.7649 1.19158 12.7186 1.28028 12.6499 1.35001L6.99994 7.00001ZM6.99994 7.00001V13.5M3.99994 13.5H9.99994"
      stroke="#49518E"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  ),
})
