import { createIcon } from '@chakra-ui/react'

export const Twitter = createIcon({
  displayName: 'Twitter',
  viewBox: '0 0 32 32',
  path: [
    <circle key="path1" cx="16" cy="16" r="16" fill="currentFill" />,
    <path
      key="path2"
      d="M22.6668 10.6733C22.0002 11 21.3468 11.1327 20.6668 11.3333C19.9195 10.49 18.8115 10.4433 17.7468 10.842C16.6822 11.2407 15.9848 12.2153 16.0002 13.3333V14C13.8368 14.0553 11.9102 13.07 10.6668 11.3333C10.6668 11.3333 7.87883 16.2887 13.3335 18.6667C12.0855 19.498 10.8408 20.0587 9.3335 20C11.5388 21.202 13.9422 21.6153 16.0228 21.0113C18.4095 20.318 20.3708 18.5293 21.1235 15.85C21.348 15.0351 21.4595 14.1932 21.4548 13.348C21.4535 13.182 22.4615 11.5 22.6668 10.6727V10.6733Z"
      fill="currentColor"
    />,
  ],
})
