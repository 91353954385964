import { createIcon } from '@chakra-ui/react'

export const MessageBubbles = createIcon({
  displayName: 'MessageBubbles',
  viewBox: '0 0 20 20',
  path: (
    <path
      d="M17.5 5.6242H16.875V2.5C16.8742 1.46484 16.0352 0.62578 15 0.625H2.5C1.46484 0.625781 0.62578 1.46484 0.625 2.5V12.9594C0.625 13.2086 0.772656 13.4336 1.00156 13.5328C1.22968 13.6313 1.49452 13.586 1.67656 13.4157L3.99696 11.2493H5.62508V13.7501C5.62586 14.7852 6.46492 15.6243 7.50008 15.6251H15.9915L18.3087 17.9423H18.3079C18.4868 18.1204 18.7556 18.1743 18.9891 18.0774C19.2227 17.9805 19.3751 17.7532 19.3751 17.5001V7.49927C19.3743 6.46411 18.5352 5.62583 17.5001 5.62427L17.5 5.6242ZM3.75 9.9992C3.59141 9.9992 3.43906 10.0601 3.32344 10.168L1.875 11.5203V2.49989C1.875 2.15457 2.15468 1.87489 2.5 1.87489H15C15.3453 1.87489 15.625 2.15457 15.625 2.49989V9.37409C15.625 9.71941 15.3453 9.99909 15 9.99909L3.75 9.9992ZM18.125 15.9914L16.6922 14.5578C16.575 14.4406 16.4156 14.375 16.25 14.375H7.5C7.15468 14.375 6.875 14.0953 6.875 13.75V11.2492H15C16.0352 11.2484 16.8742 10.4093 16.875 9.37419V6.87419H17.5C17.8453 6.87497 18.125 7.15465 18.125 7.49919V15.9914Z"
      fill="currentColor"
    />
  ),
})
