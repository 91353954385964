import { createIcon } from '@chakra-ui/react'

export const Dashboard = createIcon({
  displayName: 'Dashboard',
  viewBox: '0 0 12 12',
  path: (
    <path
      d="M4.83333 6.58329H0.749997C0.595288 6.58329 0.446915 6.64475 0.337518 6.75415C0.228122 6.86354 0.166664 7.01192 0.166664 7.16663V11.25C0.166664 11.4047 0.228122 11.553 0.337518 11.6624C0.446915 11.7718 0.595288 11.8333 0.749997 11.8333H4.83333C4.98804 11.8333 5.13641 11.7718 5.24581 11.6624C5.35521 11.553 5.41666 11.4047 5.41666 11.25V7.16663C5.41666 7.01192 5.35521 6.86354 5.24581 6.75415C5.13641 6.64475 4.98804 6.58329 4.83333 6.58329ZM4.25 10.6666H1.33333V7.74996H4.25V10.6666ZM11.25 0.166626H7.16666C7.01195 0.166626 6.86358 0.228084 6.75419 0.33748C6.64479 0.446877 6.58333 0.59525 6.58333 0.749959V4.83329C6.58333 4.988 6.64479 5.13638 6.75419 5.24577C6.86358 5.35517 7.01195 5.41663 7.16666 5.41663H11.25C11.4047 5.41663 11.5531 5.35517 11.6625 5.24577C11.7719 5.13638 11.8333 4.988 11.8333 4.83329V0.749959C11.8333 0.59525 11.7719 0.446877 11.6625 0.33748C11.5531 0.228084 11.4047 0.166626 11.25 0.166626V0.166626ZM10.6667 4.24996H7.75V1.33329H10.6667V4.24996ZM11.25 6.58329H7.16666C7.01195 6.58329 6.86358 6.64475 6.75419 6.75415C6.64479 6.86354 6.58333 7.01192 6.58333 7.16663V11.25C6.58333 11.4047 6.64479 11.553 6.75419 11.6624C6.86358 11.7718 7.01195 11.8333 7.16666 11.8333H11.25C11.4047 11.8333 11.5531 11.7718 11.6625 11.6624C11.7719 11.553 11.8333 11.4047 11.8333 11.25V7.16663C11.8333 7.01192 11.7719 6.86354 11.6625 6.75415C11.5531 6.64475 11.4047 6.58329 11.25 6.58329ZM10.6667 10.6666H7.75V7.74996H10.6667V10.6666ZM4.83333 0.166626H0.749997C0.595288 0.166626 0.446915 0.228084 0.337518 0.33748C0.228122 0.446877 0.166664 0.59525 0.166664 0.749959V4.83329C0.166664 4.988 0.228122 5.13638 0.337518 5.24577C0.446915 5.35517 0.595288 5.41663 0.749997 5.41663H4.83333C4.98804 5.41663 5.13641 5.35517 5.24581 5.24577C5.35521 5.13638 5.41666 4.988 5.41666 4.83329V0.749959C5.41666 0.59525 5.35521 0.446877 5.24581 0.33748C5.13641 0.228084 4.98804 0.166626 4.83333 0.166626V0.166626ZM4.25 4.24996H1.33333V1.33329H4.25V4.24996Z"
      fill="currentColor"
    />
  ),
})
