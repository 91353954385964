import { createIcon } from '@chakra-ui/react'

export const Bell = createIcon({
  displayName: 'Bell',
  viewBox: '0 0 16 18',
  path: (
    <path
      fill="#393E75"
      fillRule="evenodd"
      d="M7.721 0a6.261 6.261 0 0 0-6.26 6.262v2.211L.38 11.497c-.118.329-.22.616-.286.855-.068.248-.122.528-.08.825.085.63.473 1.18 1.037 1.473.266.138.547.182.803.2.248.02.554.02.902.02h1.834a3.131 3.131 0 0 0 6.261 0h1.834c.35 0 .654 0 .902-.02.257-.018.538-.062.803-.2a1.958 1.958 0 0 0 1.038-1.473c.04-.297-.013-.576-.08-.825-.066-.24-.168-.526-.287-.855l-1.08-3.024V6.262A6.261 6.261 0 0 0 7.723 0Zm1.566 14.87H6.156a1.565 1.565 0 0 0 3.13 0Zm3.37-1.564c.386 0 .63-.001.813-.014a.766.766 0 0 0 .202-.031.392.392 0 0 0 .206-.294c-.001.014.008-.025-.04-.199-.048-.176-.13-.406-.26-.77l-1.11-3.107a.877.877 0 0 1-.051-.298v-2.33a4.696 4.696 0 1 0-9.392 0v2.33c0 .108-.02.208-.052.298l-1.11 3.108c-.13.363-.212.593-.26.77a.875.875 0 0 0-.04.2.392.392 0 0 0 .206.293c.005.002.054.019.202.03.182.014.427.014.812.014h9.874Z"
      clipRule="evenodd"
    />
  ),
})
