import { createIcon } from '@chakra-ui/react'

export const OpenLink = createIcon({
  displayName: 'OpenLink',
  viewBox: '0 0 12 12',
  path: [
    <g clipPath="url(#clip0_1660:7129)" key="path1">
      <path
        d="M11.4541 0H7.0905C6.78926 0 6.54504 0.244219 6.54504 0.545461C6.54504 0.846703 6.78926 1.09092 7.0905 1.09092H10.1373L4.52301 6.70524C4.31004 6.91821 4.31004 7.26359 4.52301 7.47661C4.62949 7.58309 4.76908 7.63636 4.90868 7.63636C5.04827 7.63636 5.18789 7.58309 5.29434 7.47659L10.9087 1.8623V4.9091C10.9087 5.21034 11.1529 5.45456 11.4542 5.45456C11.7554 5.45456 11.9996 5.21034 11.9996 4.9091V0.545461C11.9996 0.244219 11.7554 0 11.4541 0Z"
        fill="currentColor"
      />
      <path
        d="M9.27272 5.45451C8.97148 5.45451 8.72726 5.69873 8.72726 5.99997V10.9091H1.0909V3.27269H6C6.30124 3.27269 6.54546 3.02847 6.54546 2.72723C6.54546 2.42599 6.30124 2.18179 6 2.18179H0.545461C0.244219 2.18179 0 2.42601 0 2.72725V11.4545C0 11.7558 0.244219 12 0.545461 12H9.27274C9.57399 12 9.8182 11.7558 9.8182 11.4545V5.99997C9.81818 5.69873 9.57396 5.45451 9.27272 5.45451Z"
        fill="currentColor"
      />
    </g>,
    <defs key="path2">
      <clipPath id="clip0_1660:7129">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>,
  ],
})
