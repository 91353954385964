import { createIcon } from '@chakra-ui/react'

export const Instagram = createIcon({
  displayName: 'Instagram',
  viewBox: '0 0 40 40',
  path: [
    <circle key="path1" cx="20" cy="20" r="20" fill="currentFill" />,
    <path
      key="path2"
      d="M19.9294 15.723C17.6587 15.723 15.8272 17.5545 15.8272 19.8252C15.8272 22.0959 17.6587 23.9274 19.9294 23.9274C22.2 23.9274 24.0316 22.0959 24.0316 19.8252C24.0316 17.5545 22.2 15.723 19.9294 15.723ZM19.9294 22.4922C18.462 22.4922 17.2624 21.2961 17.2624 19.8252C17.2624 18.3543 18.4584 17.1582 19.9294 17.1582C21.4003 17.1582 22.5963 18.3543 22.5963 19.8252C22.5963 21.2961 21.3967 22.4922 19.9294 22.4922ZM25.1562 15.5552C25.1562 16.0872 24.7277 16.512 24.1994 16.512C23.6674 16.512 23.2425 16.0836 23.2425 15.5552C23.2425 15.0268 23.671 14.5984 24.1994 14.5984C24.7277 14.5984 25.1562 15.0268 25.1562 15.5552ZM27.8731 16.5263C27.8124 15.2446 27.5197 14.1093 26.5807 13.1739C25.6453 12.2384 24.51 11.9457 23.2282 11.8814C21.9073 11.8065 17.9479 11.8065 16.6269 11.8814C15.3487 11.9421 14.2134 12.2349 13.2744 13.1703C12.3355 14.1057 12.0463 15.241 11.982 16.5227C11.907 17.8437 11.907 21.8031 11.982 23.1241C12.0427 24.4058 12.3355 25.5411 13.2744 26.4765C14.2134 27.4119 15.3452 27.7047 16.6269 27.769C17.9479 27.8439 21.9073 27.8439 23.2282 27.769C24.51 27.7083 25.6453 27.4155 26.5807 26.4765C27.5161 25.5411 27.8089 24.4058 27.8731 23.1241C27.9481 21.8031 27.9481 17.8473 27.8731 16.5263ZM26.1665 24.5415C25.8881 25.2412 25.349 25.7803 24.6456 26.0624C23.5924 26.4801 21.0932 26.3837 19.9294 26.3837C18.7655 26.3837 16.2627 26.4765 15.2131 26.0624C14.5133 25.7839 13.9742 25.2448 13.6922 24.5415C13.2744 23.4883 13.3708 20.9891 13.3708 19.8252C13.3708 18.6613 13.278 16.1586 13.6922 15.1089C13.9706 14.4092 14.5097 13.87 15.2131 13.588C16.2663 13.1703 18.7655 13.2667 19.9294 13.2667C21.0932 13.2667 23.596 13.1739 24.6456 13.588C25.3454 13.8665 25.8845 14.4056 26.1665 15.1089C26.5843 16.1621 26.4879 18.6613 26.4879 19.8252C26.4879 20.9891 26.5843 23.4918 26.1665 24.5415Z"
      fill="currentColor"
    />,
  ],
})
