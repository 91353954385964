import { createIcon } from '@chakra-ui/react'

export const NewZealandFlag = createIcon({
  displayName: 'NewZealandFlag',
  viewBox: '0 0 80 60',
  path: [
    <g key="path1" clipPath="url(#clip0_6471_12453)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H80V60H0V0Z"
        fill="#00247D"
      />
      <path
        d="M61.3031 13.3986V15.2947L65.0954 13.3986H61.3031Z"
        fill="white"
      />
      <path
        d="M62.0616 15.674L61.3031 14.157L65.0954 13.3986L62.0616 15.674Z"
        fill="white"
      />
      <path
        d="M61.3031 13.3986V15.2947L57.5108 13.3986H61.3031Z"
        fill="white"
      />
      <path
        d="M60.5446 15.674L61.3031 14.157L57.5108 13.3986L60.5446 15.674Z"
        fill="white"
      />
      <path
        d="M62.385 14.1847L60.5817 14.7706L63.5569 17.7914L62.385 14.1847Z"
        fill="white"
      />
      <path
        d="M60.4554 15.6092L61.6637 14.4191L63.5569 17.7914L60.4554 15.6092Z"
        fill="white"
      />
      <path
        d="M60.2211 14.1847L62.0244 14.7706L61.393 10.578L60.2211 14.1847Z"
        fill="white"
      />
      <path
        d="M62.6195 14.1665L60.9424 14.4191L61.393 10.578L62.6195 14.1665Z"
        fill="white"
      />
      <path
        d="M60.2211 14.1847L62.0244 14.7706L59.0492 17.7914L60.2211 14.1847Z"
        fill="white"
      />
      <path
        d="M62.1507 15.6092L60.9424 14.4191L59.0492 17.7914L62.1507 15.6092Z"
        fill="white"
      />
      <path
        d="M61.3031 13.7844V15.0374L63.809 13.7844H61.3031Z"
        fill="#CC142B"
      />
      <path
        d="M61.8043 15.288L61.3031 14.2856L63.809 13.7844L61.8043 15.288Z"
        fill="#CC142B"
      />
      <path
        d="M61.3031 13.7844V15.0374L58.7972 13.7844H61.3031Z"
        fill="#CC142B"
      />
      <path
        d="M60.8019 15.288L61.3031 14.2856L58.7972 13.7844L60.8019 15.288Z"
        fill="#CC142B"
      />
      <path
        d="M62.0181 14.304L60.8264 14.6911L62.7924 16.6872L62.0181 14.304Z"
        fill="#CC142B"
      />
      <path
        d="M60.743 15.2452L61.5414 14.4588L62.7924 16.6872L60.743 15.2452Z"
        fill="#CC142B"
      />
      <path
        d="M60.588 14.304L61.7797 14.6911L61.3624 11.9207L60.588 14.304Z"
        fill="#CC142B"
      />
      <path
        d="M62.1729 14.2919L61.0647 14.4588L61.3624 11.9207L62.1729 14.2919Z"
        fill="#CC142B"
      />
      <path
        d="M60.588 14.304L61.7797 14.6911L59.8136 16.6872L60.588 14.304Z"
        fill="#CC142B"
      />
      <path
        d="M61.8631 15.2452L61.0647 14.4588L59.8136 16.6872L61.8631 15.2452Z"
        fill="#CC142B"
      />
      <path
        d="M71.2397 22.1499L71.2397 23.8372L74.6144 22.1499L71.2397 22.1499Z"
        fill="white"
      />
      <path
        d="M71.9147 24.1747L71.2397 22.8248L74.6144 22.1499L71.9147 24.1747Z"
        fill="white"
      />
      <path
        d="M71.2397 22.1499L71.2397 23.8372L67.8651 22.1499L71.2397 22.1499Z"
        fill="white"
      />
      <path
        d="M70.5648 24.1747L71.2397 22.8248L67.8651 22.1499L70.5648 24.1747Z"
        fill="white"
      />
      <path
        d="M72.2026 22.8495L70.5979 23.3709L73.2455 26.059L72.2026 22.8495Z"
        fill="white"
      />
      <path
        d="M70.4855 24.1171L71.5607 23.0581L73.2455 26.059L70.4855 24.1171Z"
        fill="white"
      />
      <path
        d="M70.277 22.8495L71.8817 23.3709L71.3198 19.64L70.277 22.8495Z"
        fill="white"
      />
      <path
        d="M72.4112 22.8333L70.9189 23.0581L71.3198 19.64L72.4112 22.8333Z"
        fill="white"
      />
      <path
        d="M70.277 22.8495L71.8817 23.3709L69.2341 26.059L70.277 22.8495Z"
        fill="white"
      />
      <path
        d="M71.9941 24.1171L70.9189 23.0581L69.2341 26.059L71.9941 24.1171Z"
        fill="white"
      />
      <path
        d="M71.2397 22.5359L71.2397 23.58L73.328 22.5359L71.2397 22.5359Z"
        fill="#CC142B"
      />
      <path
        d="M71.6574 23.7889L71.2397 22.9535L73.328 22.5359L71.6574 23.7889Z"
        fill="#CC142B"
      />
      <path
        d="M71.2397 22.5359L71.2397 23.58L69.1515 22.5359L71.2397 22.5359Z"
        fill="#CC142B"
      />
      <path
        d="M70.8221 23.7889L71.2397 22.9535L69.1515 22.5359L70.8221 23.7889Z"
        fill="#CC142B"
      />
      <path
        d="M71.8356 22.9688L70.8425 23.2914L72.4809 24.9548L71.8356 22.9688Z"
        fill="#CC142B"
      />
      <path
        d="M70.773 23.7532L71.4384 23.0978L72.4809 24.9548L70.773 23.7532Z"
        fill="#CC142B"
      />
      <path
        d="M70.6439 22.9688L71.637 23.2914L71.2892 20.9827L70.6439 22.9688Z"
        fill="#CC142B"
      />
      <path
        d="M71.9646 22.9587L71.0411 23.0978L71.2892 20.9827L71.9646 22.9587Z"
        fill="#CC142B"
      />
      <path
        d="M70.6439 22.9688L71.637 23.2914L69.9986 24.9548L70.6439 22.9688Z"
        fill="#CC142B"
      />
      <path
        d="M71.7065 23.7532L71.0411 23.0978L69.9986 24.9548L71.7065 23.7532Z"
        fill="#CC142B"
      />
      <path
        d="M49.7294 25.0515L49.7294 26.9477L53.5217 25.0515L49.7294 25.0515Z"
        fill="white"
      />
      <path
        d="M50.4878 27.3269L49.7294 25.81L53.5217 25.0515L50.4878 27.3269Z"
        fill="white"
      />
      <path
        d="M49.7294 25.0515L49.7294 26.9477L45.9371 25.0515L49.7294 25.0515Z"
        fill="white"
      />
      <path
        d="M48.9709 27.3269L49.7294 25.81L45.9371 25.0515L48.9709 27.3269Z"
        fill="white"
      />
      <path
        d="M50.8114 25.8376L49.008 26.4236L51.9833 29.4444L50.8114 25.8376Z"
        fill="white"
      />
      <path
        d="M48.8818 27.2621L50.0901 26.072L51.9833 29.4444L48.8818 27.2621Z"
        fill="white"
      />
      <path
        d="M48.6473 25.8376L50.4507 26.4236L49.8192 22.2309L48.6473 25.8376Z"
        fill="white"
      />
      <path
        d="M51.0457 25.8194L49.3687 26.072L49.8192 22.2309L51.0457 25.8194Z"
        fill="white"
      />
      <path
        d="M48.6473 25.8376L50.4507 26.4236L47.4754 29.4444L48.6473 25.8376Z"
        fill="white"
      />
      <path
        d="M50.577 27.2621L49.3687 26.072L47.4754 29.4444L50.577 27.2621Z"
        fill="white"
      />
      <path
        d="M49.7294 25.4374L49.7294 26.6903L52.2353 25.4374L49.7294 25.4374Z"
        fill="#CC142B"
      />
      <path
        d="M50.2306 26.9409L49.7294 25.9386L52.2353 25.4374L50.2306 26.9409Z"
        fill="#CC142B"
      />
      <path
        d="M49.7294 25.4374L49.7294 26.6903L47.2234 25.4374L49.7294 25.4374Z"
        fill="#CC142B"
      />
      <path
        d="M49.2282 26.9409L49.7294 25.9386L47.2234 25.4374L49.2282 26.9409Z"
        fill="#CC142B"
      />
      <path
        d="M50.4443 25.9569L49.2527 26.3441L51.2187 28.3402L50.4443 25.9569Z"
        fill="#CC142B"
      />
      <path
        d="M49.1692 26.8982L49.9677 26.1118L51.2187 28.3402L49.1692 26.8982Z"
        fill="#CC142B"
      />
      <path
        d="M49.0143 25.9569L50.2059 26.3441L49.7887 23.5736L49.0143 25.9569Z"
        fill="#CC142B"
      />
      <path
        d="M50.5991 25.9449L49.4909 26.1118L49.7887 23.5736L50.5991 25.9449Z"
        fill="#CC142B"
      />
      <path
        d="M49.0143 25.9569L50.2059 26.3441L48.2399 28.3402L49.0143 25.9569Z"
        fill="#CC142B"
      />
      <path
        d="M50.2894 26.8982L49.4909 26.1118L48.2399 28.3402L50.2894 26.8982Z"
        fill="#CC142B"
      />
      <path
        d="M61.3031 43.3445V45.4495L65.5131 43.3445H61.3031Z"
        fill="white"
      />
      <path
        d="M62.1451 45.8705L61.3031 44.1865L65.5131 43.3445L62.1451 45.8705Z"
        fill="white"
      />
      <path
        d="M61.3031 43.3445V45.4495L57.0931 43.3445H61.3031Z"
        fill="white"
      />
      <path
        d="M60.4611 45.8705L61.3031 44.1865L57.0931 43.3445L60.4611 45.8705Z"
        fill="white"
      />
      <path
        d="M62.5043 44.2172L60.5023 44.8676L63.8052 48.2211L62.5043 44.2172Z"
        fill="white"
      />
      <path
        d="M60.3621 45.7985L61.7035 44.4774L63.8052 48.2211L60.3621 45.7985Z"
        fill="white"
      />
      <path
        d="M60.1019 44.2172L62.1039 44.8676L61.4029 40.2132L60.1019 44.2172Z"
        fill="white"
      />
      <path
        d="M62.7645 44.197L60.9027 44.4774L61.4029 40.2132L62.7645 44.197Z"
        fill="white"
      />
      <path
        d="M60.1019 44.2172L62.1039 44.8676L58.801 48.2211L60.1019 44.2172Z"
        fill="white"
      />
      <path
        d="M62.2441 45.7985L60.9027 44.4774L58.801 48.2211L62.2441 45.7985Z"
        fill="white"
      />
      <path
        d="M61.3031 43.7303V45.1921L64.2267 43.7303H61.3031Z"
        fill="#CC142B"
      />
      <path
        d="M61.8878 45.4845L61.3031 44.3151L64.2267 43.7303L61.8878 45.4845Z"
        fill="#CC142B"
      />
      <path
        d="M61.3031 43.7303V45.1921L58.3795 43.7303H61.3031Z"
        fill="#CC142B"
      />
      <path
        d="M60.7184 45.4845L61.3031 44.3151L58.3795 43.7303L60.7184 45.4845Z"
        fill="#CC142B"
      />
      <path
        d="M62.1372 44.3364L60.747 44.7881L63.0406 47.1169L62.1372 44.3364Z"
        fill="#CC142B"
      />
      <path
        d="M60.6496 45.4346L61.5811 44.5171L63.0406 47.1169L60.6496 45.4346Z"
        fill="#CC142B"
      />
      <path
        d="M60.469 44.3364L61.8592 44.7881L61.3724 41.5559L60.469 44.3364Z"
        fill="#CC142B"
      />
      <path
        d="M62.318 44.3224L61.0251 44.5171L61.3724 41.5559L62.318 44.3224Z"
        fill="#CC142B"
      />
      <path
        d="M60.469 44.3364L61.8592 44.7881L59.5656 47.1169L60.469 44.3364Z"
        fill="#CC142B"
      />
      <path
        d="M61.9566 45.4346L61.0251 44.5171L59.5656 47.1169L61.9566 45.4346Z"
        fill="#CC142B"
      />
      <path d="M0 0H40V30H0V0Z" fill="#012169" />
      <path
        d="M4.6875 0L19.9375 11.3125L35.125 0H40V3.875L25 15.0625L40 26.1875V30H35L20 18.8125L5.0625 30H0V26.25L14.9375 15.125L0 4V0H4.6875Z"
        fill="white"
      />
      <path
        d="M26.5 17.5625L40 27.5V30L23.0625 17.5625H26.5ZM15 18.8125L15.375 21L3.375 30H0L15 18.8125ZM40 0V0.1875L24.4375 11.9375L24.5625 9.1875L36.875 0H40ZM0 0L14.9375 11H11.1875L0 2.625V0Z"
        fill="#C8102E"
      />
      <path
        d="M15.0625 0V30H25.0625V0H15.0625ZM0 10V20H40V10H0Z"
        fill="white"
      />
      <path
        d="M0 12.0625V18.0625H40V12.0625H0ZM17.0625 0V30H23.0625V0H17.0625Z"
        fill="#C8102E"
      />
    </g>,
    <defs key="path2">
      <clipPath id="clip0_6471_12453">
        <rect width="80" height="60" rx="8" fill="white" />
      </clipPath>
    </defs>,
  ],
})
