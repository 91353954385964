import { createIcon } from '@chakra-ui/react'

export const CheckMark = createIcon({
  displayName: 'CheckMark',
  viewBox: '0 0 27 26',
  path: [
    <path
      key="path1"
      stroke="currentColor"
      fill="none"
      width="12px"
      height="12px"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M23.8609 11.8908V13.0067C23.858 18.3208 20.5263 22.9999 15.6727 24.5066C10.8191 26.0133 5.59961 23.9887 2.84482 19.5307C0.0900312 15.0727 0.456142 9.2433 3.74461 5.20369C7.03308 1.16408 12.4605 -0.123414 17.0837 2.03941"
    />,
    <path
      key="path2"
      stroke="currentColor"
      fill="none"
      width="12px"
      height="12px"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M25.0037 2.20801L12.4322 15.4071L9.00366 11.8074"
    />,
  ],
})
