import { createIcon } from '@chakra-ui/react'

export const Trash = createIcon({
  displayName: 'Trash',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="path1"
      fill="currentColor"
      d="M19.875 3H15.75V2.25C15.75 1.00936 14.7406 0 13.5 0H10.5C9.25936 0 8.25 1.00936 8.25 2.25V3H4.125C3.09113 3 2.25 3.84113 2.25 4.875V7.5C2.25 7.91419 2.58581 8.25 3 8.25H3.40988L4.05783 21.857C4.11506 23.0587 5.10225 24 6.30525 24H17.6947C18.8978 24 19.885 23.0587 19.9422 21.857L20.5901 8.25H21C21.4142 8.25 21.75 7.91419 21.75 7.5V4.875C21.75 3.84113 20.9089 3 19.875 3ZM9.75 2.25C9.75 1.83647 10.0865 1.5 10.5 1.5H13.5C13.9135 1.5 14.25 1.83647 14.25 2.25V3H9.75V2.25ZM3.75 4.875C3.75 4.66823 3.91823 4.5 4.125 4.5H19.875C20.0818 4.5 20.25 4.66823 20.25 4.875V6.75C20.0189 6.75 4.7078 6.75 3.75 6.75V4.875ZM18.4439 21.7857C18.4248 22.1862 18.0957 22.5 17.6947 22.5H6.30525C5.90423 22.5 5.57517 22.1862 5.55614 21.7857L4.91156 8.25H19.0884L18.4439 21.7857Z"
    />,
    <path
      key="path2"
      fill="currentColor"
      d="M12 21C12.4142 21 12.75 20.6642 12.75 20.25V10.5C12.75 10.0858 12.4142 9.75 12 9.75C11.5858 9.75 11.25 10.0858 11.25 10.5V20.25C11.25 20.6642 11.5858 21 12 21Z"
    />,
    <path
      key="path3"
      fill="currentColor"
      d="M15.75 21C16.1642 21 16.5 20.6642 16.5 20.25V10.5C16.5 10.0858 16.1642 9.75 15.75 9.75C15.3358 9.75 15 10.0858 15 10.5V20.25C15 20.6642 15.3358 21 15.75 21Z"
    />,
    <path
      key="path4"
      fill="currentColor"
      d="M8.25 21C8.66419 21 9 20.6642 9 20.25V10.5C9 10.0858 8.66419 9.75 8.25 9.75C7.83581 9.75 7.5 10.0858 7.5 10.5V20.25C7.5 20.6642 7.83577 21 8.25 21Z"
    />,
  ],
})
