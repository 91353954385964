import { createIcon } from '@chakra-ui/react'

export const Trophy = createIcon({
  displayName: 'Trophy',
  viewBox: '0 0 20 20',
  path: (
    <path
      d="M18.8496 2.5749C18.2496 1.7499 17.2746 1.2999 16.2996 1.2999H15.7746V1.2249C15.7746 0.774902 15.3996 0.399902 14.9496 0.399902H5.19957C4.74957 0.399902 4.37457 0.774902 4.37457 1.2249V1.2999H3.77457C2.72457 1.2999 1.82457 1.7499 1.14945 2.5749C0.549452 3.3249 0.324452 4.3749 0.549452 5.34978L1.07445 7.52478C1.29945 8.57478 2.04945 9.47478 3.09945 9.84978L5.87433 10.8998L7.44933 12.7748C8.12433 13.5998 8.49933 14.5748 8.64933 15.6999H7.74933C7.14933 15.6999 6.69933 16.0749 6.54933 16.6749L6.24933 18.0999C6.09933 18.8499 6.62433 19.5999 7.44933 19.5999H12.8493C13.5993 19.5999 14.1993 18.8499 14.0493 18.0999L13.7493 16.6749C13.5993 16.1499 13.1493 15.6999 12.5493 15.6999H11.6493C11.7243 14.6499 12.0993 13.5999 12.8493 12.7748L14.4243 10.8998L17.1992 9.84978C18.2492 9.47478 18.9992 8.57478 19.2242 7.52478L19.7492 5.34978C19.6742 4.37478 19.4492 3.32478 18.8492 2.5749H18.8496ZM3.54957 8.5749C2.94957 8.3499 2.49957 7.8249 2.34957 7.2249L1.82457 5.0499C1.74957 4.4499 1.82457 3.8499 2.19957 3.3999C2.57457 2.9499 3.09957 2.6499 3.69957 2.6499H4.22457V7.1499C4.22457 7.8249 4.37457 8.4249 4.59957 9.0249L3.54957 8.5749ZM11.1245 6.8499L11.4245 8.4249L9.99945 7.6749L8.57445 8.4249L8.87445 6.8499L7.74945 5.7999L9.32445 5.5749L9.99945 4.1499L10.6745 5.5749L12.2495 5.7999L11.1245 6.8499ZM18.1745 5.0499L17.6495 7.2249C17.4995 7.8249 17.0495 8.3499 16.4495 8.5749L15.3995 9.0249C15.6245 8.4249 15.7745 7.7499 15.7745 7.1499V2.6499H16.2995C16.8995 2.6499 17.4245 2.9499 17.7995 3.3999C18.1745 3.8499 18.2495 4.4499 18.1745 5.0499Z"
      fill="currentColor"
    />
  ),
})
