import { createIcon } from '@chakra-ui/react'

export const StarBold = createIcon({
  displayName: 'StarBold',
  viewBox: '0 0 20 20',
  path: (
    <path
      d="M19.4957 7.85998L19.4958 7.86016C19.5059 7.8911 19.4975 7.9252 19.4741 7.94802L19.474 7.94805L14.9873 12.3213L14.7991 12.5048L14.8435 12.7639L15.9027 18.9388C15.9083 18.971 15.895 19.0037 15.8685 19.023C15.8421 19.0421 15.807 19.0447 15.778 19.0294L10.2327 16.114L10 15.9916L9.76732 16.114L4.22176 19.0295L4.22167 19.0296C4.20914 19.0361 4.19563 19.0394 4.18185 19.0394C4.16384 19.0394 4.14627 19.0338 4.13136 19.023C4.10477 19.0037 4.09159 18.9711 4.09711 18.939L4.09712 18.9389L5.15603 12.7638L5.20045 12.5048L5.01223 12.3213L0.526 7.9481L0.525957 7.94806C0.502543 7.92524 0.494115 7.89112 0.504225 7.86002L0.504242 7.85996C0.514342 7.82887 0.541244 7.80623 0.5735 7.80154L0.573577 7.80153L6.77355 6.90056L7.03367 6.86276L7.15001 6.62705L9.92297 1.00883L9.47504 0.787751L9.92297 1.00883C9.93744 0.979505 9.96729 0.960938 10 0.960938C10.0328 0.960938 10.0626 0.979527 10.077 1.00876L10.0771 1.00883L12.8499 6.62705L12.9662 6.86276L13.2264 6.90056L19.4265 7.80153L19.4265 7.80155C19.4587 7.80622 19.4856 7.82878 19.4957 7.85998Z"
      fill="currentColor"
      stroke="currentColor"
    />
  ),
})
