import { createIcon } from '@chakra-ui/react'

export const UnitedKingdomFlag = createIcon({
  displayName: 'UnitedKingdomFlag',
  viewBox: '0 0 80 60',
  path: [
    <g key="path1" clipPath="url(#clip0_6471_11897)">
      <path d="M0 0H80V60H0V0Z" fill="#012169" />
      <path
        d="M9.375 0L39.875 22.625L70.25 0H80V7.75L50 30.125L80 52.375V60H70L40 37.625L10.125 60H0V52.5L29.875 30.25L0 8V0H9.375Z"
        fill="white"
      />
      <path
        d="M53 35.125L80 55V60L46.125 35.125H53ZM30 37.625L30.75 42L6.75 60H0L30 37.625ZM80 0V0.375L48.875 23.875L49.125 18.375L73.75 0H80ZM0 0L29.875 22H22.375L0 5.25V0Z"
        fill="#C8102E"
      />
      <path d="M30.125 0V60H50.125V0H30.125ZM0 20V40H80V20H0Z" fill="white" />
      <path
        d="M0 24.125V36.125H80V24.125H0ZM34.125 0V60H46.125V0H34.125Z"
        fill="#C8102E"
      />
    </g>,
    <defs key="path2">
      <clipPath id="clip0_6471_11897">
        <rect width="80" height="60" rx="8" fill="white" />
      </clipPath>
    </defs>,
  ],
})
