import { createIcon } from '@chakra-ui/react'

export const Star = createIcon({
  displayName: 'Star',
  viewBox: '0 0 16 16',
  path: [
    <clipPath key="path1" id="a">
      <path d="m0 0h16v16h-16z" />
    </clipPath>,
    <g key="path2" clipPath="url(#a)">
      <path
        d="m15.977 6.16431c-.0551-.16972-.2018-.2934-.3783-.31903l-4.9601-.72078-2.21827-4.494567c-.07894-.159999-.24191-.26128-.42031-.26128-.17844 0-.34138.101281-.42034.26128l-2.21837 4.494567-4.959972.72078c-.176531.02563-.3232488.14931-.3783735.319-.055156.16972-.0091562.356.1186245.48053l3.588981 3.49859-.84712 4.94c-.03019.1759.04212.3536.18647.4585.08165.0593.17837.0895.27556.0895.07462 0 .1495-.0178.21806-.0539l4.43645-2.3324 4.43621 2.3324c.158.083.3494.0692.4937-.0356.1443-.1049.2167-.2827.1865-.4585l-.8474-4.94 3.5894-3.49862c.1278-.1245.1738-.31078.1186-.48047z"
        fill="currentColor"
      />
    </g>,
  ],
})
