import { createIcon } from '@chakra-ui/react'

export const LinkedIn = createIcon({
  displayName: 'LinkedIn',
  viewBox: '0 0 32 32',
  path: [
    <circle key="path1" cx="16" cy="16" r="16" fill="currentFill" />,
    <path
      key="path2"
      d="M19.1999 12.8C20.4729 12.8 21.6938 13.3058 22.594 14.2059C23.4942 15.1061 23.9999 16.327 23.9999 17.6V23.2H20.7999V17.6C20.7999 17.1757 20.6313 16.7687 20.3313 16.4687C20.0312 16.1686 19.6242 16 19.1999 16C18.7756 16 18.3686 16.1686 18.0685 16.4687C17.7685 16.7687 17.5999 17.1757 17.5999 17.6V23.2H14.3999V17.6C14.3999 16.327 14.9056 15.1061 15.8058 14.2059C16.706 13.3058 17.9269 12.8 19.1999 12.8Z"
      fill="currentColor"
    />,
    <path
      key="path3"
      d="M11.2 13.6001H8V23.2001H11.2V13.6001Z"
      fill="currentColor"
    />,
    <path
      key="path4"
      d="M9.6 11.2C10.4837 11.2 11.2 10.4837 11.2 9.6C11.2 8.71634 10.4837 8 9.6 8C8.71634 8 8 8.71634 8 9.6C8 10.4837 8.71634 11.2 9.6 11.2Z"
      fill="currentColor"
    />,
  ],
})
