import { createIcon } from '@chakra-ui/react'

export const LockKey = createIcon({
  displayName: 'LockKey',
  viewBox: '0 0 18 18',
  path: (
    <path
      fill="currentColor"
      width="18px"
      height="18px"
      d="M9 9.75C8.77166 9.74733 8.54799 9.81468 8.35908 9.94298C8.17018 10.0713 8.02511 10.2544 7.94341 10.4676C7.86171 10.6808 7.8473 10.914 7.90211 11.1357C7.95693 11.3573 8.07834 11.5569 8.25 11.7075V12.75C8.25 12.9489 8.32902 13.1397 8.46967 13.2803C8.61032 13.421 8.80109 13.5 9 13.5C9.19891 13.5 9.38968 13.421 9.53033 13.2803C9.67098 13.1397 9.75 12.9489 9.75 12.75V11.7075C9.92166 11.5569 10.0431 11.3573 10.0979 11.1357C10.1527 10.914 10.1383 10.6808 10.0566 10.4676C9.97489 10.2544 9.82982 10.0713 9.64092 9.94298C9.45201 9.81468 9.22834 9.74733 9 9.75ZM12.75 6.75V5.25C12.75 4.25544 12.3549 3.30161 11.6517 2.59835C10.9484 1.89509 9.99456 1.5 9 1.5C8.00544 1.5 7.05161 1.89509 6.34835 2.59835C5.64509 3.30161 5.25 4.25544 5.25 5.25V6.75C4.65326 6.75 4.08097 6.98705 3.65901 7.40901C3.23705 7.83097 3 8.40326 3 9V14.25C3 14.8467 3.23705 15.419 3.65901 15.841C4.08097 16.2629 4.65326 16.5 5.25 16.5H12.75C13.3467 16.5 13.919 16.2629 14.341 15.841C14.7629 15.419 15 14.8467 15 14.25V9C15 8.40326 14.7629 7.83097 14.341 7.40901C13.919 6.98705 13.3467 6.75 12.75 6.75ZM6.75 5.25C6.75 4.65326 6.98705 4.08097 7.40901 3.65901C7.83097 3.23705 8.40326 3 9 3C9.59674 3 10.169 3.23705 10.591 3.65901C11.0129 4.08097 11.25 4.65326 11.25 5.25V6.75H6.75V5.25ZM13.5 14.25C13.5 14.4489 13.421 14.6397 13.2803 14.7803C13.1397 14.921 12.9489 15 12.75 15H5.25C5.05109 15 4.86032 14.921 4.71967 14.7803C4.57902 14.6397 4.5 14.4489 4.5 14.25V9C4.5 8.80109 4.57902 8.61032 4.71967 8.46967C4.86032 8.32902 5.05109 8.25 5.25 8.25H12.75C12.9489 8.25 13.1397 8.32902 13.2803 8.46967C13.421 8.61032 13.5 8.80109 13.5 9V14.25Z"
    />
  ),
})
