import { createIcon } from '@chakra-ui/react'

export const SortDescending = createIcon({
  displayName: 'SortDescending',
  viewBox: '0 0 16 16',
  path: [
    <g key="path1" clipPath="url(#clip0_1364_5061)">
      <path
        d="M13.333 4.66659H7.9997C7.76151 4.66659 7.54144 4.5395 7.42233 4.33325C7.30323 4.12699 7.30323 3.87284 7.42233 3.66659C7.54143 3.46033 7.76149 3.33325 7.9997 3.33325H13.333C13.5712 3.33325 13.7913 3.46033 13.9104 3.66659C14.0295 3.87284 14.0295 4.12699 13.9104 4.33325C13.7913 4.53951 13.5712 4.66659 13.333 4.66659Z"
        fill="currentColor"
      />
      <path
        d="M13.333 9.99984H7.9997C7.76151 9.99984 7.54144 9.87275 7.42233 9.6665C7.30323 9.46025 7.30323 9.2061 7.42233 8.99984C7.54143 8.79358 7.76149 8.6665 7.9997 8.6665H13.333C13.5712 8.6665 13.7913 8.79359 13.9104 8.99984C14.0295 9.2061 14.0295 9.46025 13.9104 9.6665C13.7913 9.87276 13.5712 9.99984 13.333 9.99984Z"
        fill="currentColor"
      />
      <path
        d="M11.9997 7.33333H7.9997C7.76151 7.33333 7.54144 7.20625 7.42233 7C7.30323 6.79374 7.30323 6.53959 7.42233 6.33333C7.54143 6.12708 7.76149 6 7.9997 6H11.9997C12.2379 6 12.458 6.12708 12.5771 6.33333C12.6962 6.53959 12.6962 6.79374 12.5771 7C12.458 7.20626 12.2379 7.33333 11.9997 7.33333Z"
        fill="currentColor"
      />
      <path
        d="M11.9997 12.6666H7.9997C7.76151 12.6666 7.54144 12.5395 7.42233 12.3333C7.30323 12.127 7.30323 11.8728 7.42233 11.6666C7.54143 11.4603 7.76149 11.3333 7.9997 11.3333H11.9997C12.2379 11.3333 12.458 11.4603 12.5771 11.6666C12.6962 11.8728 12.6962 12.127 12.5771 12.3333C12.458 12.5395 12.2379 12.6666 11.9997 12.6666Z"
        fill="currentColor"
      />
      <path
        d="M3.99991 12.6667C3.82268 12.6678 3.6524 12.5982 3.52657 12.4734C3.40039 12.3482 3.32935 12.1778 3.32935 12.0001C3.32935 11.8223 3.40039 11.6519 3.52657 11.5267L4.85991 10.1934C5.029 10.0243 5.27546 9.95825 5.5065 10.0201C5.73747 10.082 5.91797 10.2625 5.97983 10.4935C6.04171 10.7245 5.97567 10.971 5.80657 11.1401L4.47324 12.4734C4.3474 12.5982 4.17713 12.6678 3.9999 12.6667L3.99991 12.6667Z"
        fill="currentColor"
      />
      <path
        d="M3.99982 12.6667C3.82259 12.6678 3.65231 12.5982 3.52648 12.4734L2.19315 11.1401C2.02405 10.971 1.958 10.7245 2.01989 10.4935C2.08176 10.2625 2.26225 10.082 2.49322 10.0201C2.72426 9.95825 2.97071 10.0243 3.13981 10.1934L4.47315 11.5267C4.59933 11.6519 4.67037 11.8223 4.67037 12.0001C4.67037 12.1778 4.59933 12.3482 4.47315 12.4734C4.34731 12.5982 4.17704 12.6678 3.99981 12.6667L3.99982 12.6667Z"
        fill="currentColor"
      />
      <path
        d="M3.99992 12.6666C3.82311 12.6666 3.65354 12.5964 3.52852 12.4713C3.40345 12.3463 3.33325 12.1768 3.33325 11.9999V3.99994C3.33325 3.76176 3.46033 3.54169 3.66659 3.42257C3.87284 3.30348 4.12699 3.30348 4.33325 3.42257C4.53951 3.54167 4.66659 3.76174 4.66659 3.99994V11.9999C4.66659 12.1767 4.59638 12.3463 4.47131 12.4713C4.34631 12.5964 4.17674 12.6666 3.99992 12.6666Z"
        fill="currentColor"
      />
    </g>,
  ],
})
