import { createIcon } from '@chakra-ui/react'

const getWarningIcon = (color: string) =>
  createIcon({
    displayName: 'Warning',
    viewBox: '0 0 16 16',
    path: [
      <circle key="path1" cx="8" cy="8" r="8" fill="currentColor" />,
      <path
        key="path2"
        d="M7.15755 3.85385C7.14574 3.7362 7.15872 3.61737 7.19566 3.50504C7.23259 3.39271 7.29266 3.28937 7.37199 3.20168C7.45131 3.114 7.54814 3.04391 7.65622 2.99594C7.7643 2.94797 7.88123 2.92319 7.99947 2.92319C8.11772 2.92319 8.23465 2.94797 8.34273 2.99594C8.45081 3.04391 8.54764 3.114 8.62696 3.20168C8.70629 3.28937 8.76636 3.39271 8.80329 3.50504C8.84023 3.61737 8.85321 3.7362 8.8414 3.85385L8.51309 9.22693C8.50041 9.35434 8.44082 9.47249 8.3459 9.55843C8.25099 9.64436 8.12752 9.69195 7.99947 9.69195C7.87143 9.69195 7.74796 9.64436 7.65305 9.55843C7.55813 9.47249 7.49854 9.35434 7.48586 9.22693L7.15755 3.85385ZM7.15332 11.3846C7.15332 11.1602 7.24247 10.945 7.40115 10.7863C7.55984 10.6276 7.77506 10.5385 7.99947 10.5385C8.22389 10.5385 8.43911 10.6276 8.5978 10.7863C8.75648 10.945 8.84563 11.1602 8.84563 11.3846C8.84563 11.609 8.75648 11.8243 8.5978 11.9829C8.43911 12.1416 8.22389 12.2308 7.99947 12.2308C7.77506 12.2308 7.55984 12.1416 7.40115 11.9829C7.24247 11.8243 7.15332 11.609 7.15332 11.3846Z"
        fill={color}
      />,
    ],
  })

export const Warning = getWarningIcon('#FF8400')
export const WarningYellow = getWarningIcon('#FBC756')
