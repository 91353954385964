import { createIcon } from '@chakra-ui/react'

export const SortAscending = createIcon({
  displayName: 'SortAscending',
  viewBox: '0 0 16 16',
  path: (
    <g clipPath="url(#clip0_1297_5257)">
      <path
        d="M13.3333 4.66659H7.99994C7.76176 4.66659 7.54169 4.5395 7.42257 4.33325C7.30348 4.12699 7.30348 3.87284 7.42257 3.66659C7.54167 3.46033 7.76174 3.33325 7.99994 3.33325H13.3333C13.5715 3.33325 13.7915 3.46033 13.9106 3.66659C14.0297 3.87284 14.0297 4.12699 13.9106 4.33325C13.7915 4.53951 13.5715 4.66659 13.3333 4.66659Z"
        fill="currentColor"
      />
      <path
        d="M13.3333 10.0001H7.99994C7.76176 10.0001 7.54169 9.873 7.42257 9.66675C7.30348 9.46049 7.30348 9.20634 7.42257 9.00008C7.54167 8.79382 7.76174 8.66675 7.99994 8.66675H13.3333C13.5715 8.66675 13.7915 8.79383 13.9106 9.00008C14.0297 9.20634 14.0297 9.46049 13.9106 9.66675C13.7915 9.87301 13.5715 10.0001 13.3333 10.0001Z"
        fill="currentColor"
      />
      <path
        d="M11.9999 7.33333H7.99994C7.76176 7.33333 7.54169 7.20625 7.42257 7C7.30348 6.79374 7.30348 6.53959 7.42257 6.33333C7.54167 6.12708 7.76174 6 7.99994 6H11.9999C12.2381 6 12.4582 6.12708 12.5773 6.33333C12.6964 6.53959 12.6964 6.79374 12.5773 7C12.4582 7.20626 12.2381 7.33333 11.9999 7.33333Z"
        fill="currentColor"
      />
      <path
        d="M11.9999 12.6666H7.99994C7.76176 12.6666 7.54169 12.5395 7.42257 12.3333C7.30348 12.127 7.30348 11.8728 7.42257 11.6666C7.54167 11.4603 7.76174 11.3333 7.99994 11.3333H11.9999C12.2381 11.3333 12.4582 11.4603 12.5773 11.6666C12.6964 11.8728 12.6964 12.127 12.5773 12.3333C12.4582 12.5395 12.2381 12.6666 11.9999 12.6666Z"
        fill="currentColor"
      />
      <path
        d="M2.66665 5.99998C2.48943 6.00102 2.31915 5.93144 2.19332 5.80665C2.06714 5.68144 1.99609 5.51109 1.99609 5.33331C1.99609 5.15553 2.06714 4.98519 2.19332 4.85998L3.52665 3.52665C3.69575 3.35755 3.94221 3.2915 4.17325 3.35338C4.40422 3.41526 4.58472 3.59575 4.64658 3.82672C4.70846 4.05776 4.64242 4.30421 4.47332 4.47331L3.13999 5.80664C3.01415 5.93144 2.84388 6.00103 2.66665 5.99998L2.66665 5.99998Z"
        fill="currentColor"
      />
      <path
        d="M5.33331 5.99997C5.15609 6.00102 4.98581 5.93143 4.85998 5.80664L3.52665 4.47331C3.35755 4.30421 3.2915 4.05775 3.35338 3.82671C3.41526 3.59574 3.59575 3.41525 3.82671 3.35338C4.05776 3.2915 4.30421 3.35755 4.47331 3.52664L5.80664 4.85998C5.93283 4.98518 6.00387 5.15553 6.00387 5.33331C6.00387 5.51109 5.93283 5.68143 5.80664 5.80664C5.68081 5.93143 5.51054 6.00102 5.33331 5.99998L5.33331 5.99997Z"
        fill="currentColor"
      />
      <path
        d="M3.99992 12.6666C3.82311 12.6666 3.65354 12.5964 3.52852 12.4713C3.40345 12.3463 3.33325 12.1768 3.33325 11.9999V3.99994C3.33325 3.76176 3.46033 3.54169 3.66659 3.42257C3.87284 3.30348 4.12699 3.30348 4.33325 3.42257C4.53951 3.54167 4.66659 3.76174 4.66659 3.99994V11.9999C4.66659 12.1767 4.59638 12.3463 4.47131 12.4713C4.34631 12.5964 4.17674 12.6666 3.99992 12.6666Z"
        fill="currentColor"
      />
    </g>
  ),
})
