import { createIcon } from '@chakra-ui/react'

export const Messages = createIcon({
  displayName: 'Messages',
  viewBox: '0 0 18 17',
  path: (
    <path
      fill="#393E75"
      d="M15.75 5.062h-.563V2.25c0-.932-.755-1.687-1.687-1.687H2.25C1.318.563.563 1.318.562 2.25v9.414a.562.562 0 0 0 .947.41l2.088-1.95h1.466v2.251c0 .932.755 1.687 1.687 1.688h7.642l2.086 2.085a.563.563 0 0 0 .96-.398v-9a1.69 1.69 0 0 0-1.688-1.688ZM3.375 8.999a.563.563 0 0 0-.384.152l-1.304 1.217V2.25c0-.31.252-.563.563-.563H13.5c.31 0 .563.252.563.563v6.187c0 .31-.252.562-.563.562H3.375Zm12.938 5.393-1.29-1.29a.563.563 0 0 0-.398-.165H6.75a.562.562 0 0 1-.563-.562v-2.25H13.5c.932-.001 1.687-.757 1.688-1.688v-2.25h.562c.31 0 .563.252.563.562v7.643Z"
    />
  ),
})
