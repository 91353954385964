import { createIcon } from '@chakra-ui/react'

export const ExternalLink = createIcon({
  displayName: 'ExternalLink',
  viewBox: '0 0 12 12',
  path: [
    <path
      key="path1"
      fill="currentColor"
      d="M11.4541 0H7.0905C6.78925 0 6.54504 0.244219 6.54504 0.545461C6.54504 0.846703 6.78925 1.09092 7.0905 1.09092H10.1373L4.52301 6.70524C4.31004 6.91821 4.31004 7.26359 4.52301 7.47661C4.62949 7.58309 4.76908 7.63636 4.90868 7.63636C5.04827 7.63636 5.18789 7.58309 5.29434 7.47659L10.9087 1.8623V4.9091C10.9087 5.21034 11.1529 5.45456 11.4542 5.45456C11.7554 5.45456 11.9996 5.21034 11.9996 4.9091V0.545461C11.9996 0.244219 11.7554 0 11.4541 0Z"
    />,
    <path
      key="path2"
      fill="currentColor"
      d="M9.27272 5.45448C8.97148 5.45448 8.72726 5.6987 8.72726 5.99994V10.909H1.0909V3.27266H6C6.30124 3.27266 6.54546 3.02844 6.54546 2.7272C6.54546 2.42596 6.30124 2.18176 6 2.18176H0.545461C0.244219 2.18176 0 2.42598 0 2.72722V11.4545C0 11.7557 0.244219 11.9999 0.545461 11.9999H9.27274C9.57398 11.9999 9.8182 11.7557 9.8182 11.4545V5.99994C9.81818 5.6987 9.57396 5.45448 9.27272 5.45448Z"
    />,
  ],
})
