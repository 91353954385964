import { createIcon } from '@chakra-ui/react'

export const Cube = createIcon({
  displayName: 'Cube',
  viewBox: '0 0 20 20',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 2C20 0.89584 19.1042 0 18 0H2C0.89584 0 0 0.89584 0 2V18C0 19.1042 0.89584 20 2 20H18C19.1042 20 20 19.1042 20 18V2ZM15.2011 13.2C16.3042 13.2 17.2011 14.0958 17.2011 15.2C17.2011 16.3042 16.3042 17.2 15.2011 17.2C14.0969 17.2 13.2011 16.3042 13.2011 15.2C13.2011 14.0958 14.0969 13.2 15.2011 13.2ZM10 8C11.1042 8 12 8.89584 12 10C12 11.1042 11.1042 12 10 12C8.89584 12 8 11.1042 8 10C8 8.89584 8.89584 8 10 8ZM4.80107 2.8C5.90419 2.8 6.80107 3.69584 6.80107 4.8C6.80107 5.90416 5.90419 6.8 4.80107 6.8C3.69691 6.8 2.80107 5.90416 2.80107 4.8C2.80107 3.69584 3.69691 2.8 4.80107 2.8Z"
      fill="currentColor"
    />
  ),
})
