import { ChakraProps, Icon } from '@chakra-ui/react'

import { Colors } from 'theme/constants'

const DEFAULT_COLOR = Colors.brand.purpleNavy

type IconInfoProps = {
  color?: string
} & ChakraProps

export const IconInfo = ({ color = DEFAULT_COLOR, ...rest }: IconInfoProps) => {
  return (
    <Icon {...rest} viewBox="0 0 20 20">
      <path
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.667"
        d="M10 7.5h.008M2.5 10a7.5 7.5 0 1 0 15 0 7.5 7.5 0 0 0-15 0Z"
        key="path1"
      />
      <path
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.667"
        d="M9.167 10H10v3.333h.834"
        key="path2"
      />
    </Icon>
  )
}
