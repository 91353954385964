import { createIcon } from '@chakra-ui/react'

export const AustraliaFlag = createIcon({
  displayName: 'AustraliaFlag',
  viewBox: '0 0 80 60',
  path: [
    <g key="path1" clipPath="url(#clip0_6471_12446)">
      <path d="M0 0H80V60H0V0Z" fill="#00008B" />
      <path
        d="M4.6875 0L19.9375 11.3125L35.125 0H40V3.875L25 15.0625L40 26.1875V30H35L20 18.8125L5.0625 30H0V26.25L14.9375 15.125L0 4V0H4.6875Z"
        fill="white"
      />
      <path
        d="M26.5 17.5625L40 27.5V30L23.0625 17.5625H26.5ZM15 18.8125L15.375 21L3.375 30H0L15 18.8125ZM40 0V0.1875L24.4375 11.9375L24.5625 9.1875L36.875 0H40ZM0 0L14.9375 11H11.1875L0 2.625V0Z"
        fill="#FF0000"
      />
      <path
        d="M15.0625 0V30H25.0625V0H15.0625ZM0 10V20H40V10H0Z"
        fill="white"
      />
      <path
        d="M0 12.0625V18.0625H40V12.0625H0ZM17.0625 0V30H23.0625V0H17.0625Z"
        fill="#FF0000"
      />
      <path
        d="M65.875 49.5876L63.3125 49.9125L63.5875 52.475L61.7374 50.675L59.9 52.4875L60.15 49.925L57.5875 49.625L59.75 48.225L58.3875 46.0375L60.8375 46.85L61.7 44.4125L62.5875 46.8376L65.025 46L63.6875 48.2001L65.8625 49.5876H65.875ZM65.4124 34.9375L65.75 33.3125L64.525 32.1875L66.175 32L66.8625 30.4875L67.55 32L69.1999 32.1875L67.9749 33.3125L68.3125 34.9375L66.8625 34.1125L65.4124 34.9375ZM52.4 27.4375L49.8624 27.7125L50.0875 30.25L48.2874 28.4375L46.4375 30.2001L46.7374 27.6625L44.2125 27.3251L46.375 25.975L45.0625 23.7875L47.4749 24.6376L48.375 22.25L49.2125 24.6625L51.6375 23.875L50.275 26.0375L52.4124 27.4375H52.4ZM77.875 23.3375L75.2625 23.675L75.55 26.2875L73.6624 24.4501L71.7874 26.3L72.05 23.675L69.4375 23.375L71.65 21.9375L70.2624 19.7L72.7624 20.5375L73.6374 18.0625L74.5374 20.5375L77.025 19.675L75.65 21.925L77.875 23.3375ZM65.8625 12.9L63.275 13.1875L63.5125 15.7875L61.6749 13.9375L59.7874 15.7375L60.0875 13.15L57.5 12.8L59.7125 11.425L58.375 9.18755L60.8375 10.05L61.75 7.61255L62.5999 10.075L65.0749 9.26255L63.6875 11.4625L65.8625 12.9ZM29.25 48.2126L23.525 48.8876L24.1 54.625L20 50.5751L15.875 54.6L16.4875 48.8625L10.7625 48.1376L15.625 45.0375L12.625 40.1125L18.075 41.9875L20.05 36.5625L21.9875 42L27.45 40.1625L24.4125 45.0625L29.2625 48.2001L29.25 48.2126Z"
        fill="white"
      />
    </g>,
    <defs key="path2">
      <clipPath id="clip0_6471_12446">
        <rect width="80" height="60" rx="8" fill="white" />
      </clipPath>
    </defs>,
  ],
})
